import React, { Component } from 'react';

class Footer extends Component {
    state = {  } 
    render() { 
        return <div className="footer">
            <nav className="navbar bg-body-tertiary" data-bs-theme="dark">
                <div className="container-fluid">
                    <p className="navbar-brand footerContent" href="#">
                        © Terminarthur  |  2024
                    </p>
                </div>
            </nav>
        </div>;
    }
}
 
export default Footer;