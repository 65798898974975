import React, { Component } from 'react';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Product from "./components/product";
import ShoppingCart from "./components/shopping-cart";
import { BrowserRouter, Route, Switch } from 'react-router-dom';


class App extends Component {
    state = {
        items: []
    }

    addItem = (amount, name, price) => {
        let currentItems = this.state.items;
        let existingItem = this.state.items.find(item => item.name == name);
        if (existingItem) {
            existingItem.amount++;
        } else {
            currentItems.push({
                amount,
                name,
                price
            });
        }

        this.setState({ items: currentItems })
        console.log(this.state);
    }
    render() {
        return <React.Fragment>
            <Navbar />
            
            <div className="main-container">
                <div className="product-container">
                    <Product onAdd={() => this.addItem(1, 'strawberrys', 2.99)} title="Strawberrys" img="strawberrys.jpg" description="Sweet and in the Color of Love! Always Yummy! Only Today for the Price of 0.99 Euro!" />
                    <Product onAdd={() => this.addItem(1, 'tomatoes', 1.99)} title="Tomatoes" img="tomatoes.jpg" description="Sour, but also in the Color of Love. You'll never forget the Taste of our fresh Bio Tomatoes." />
                    <Product onAdd={() => this.addItem(1, 'cucumbers', 3.99)} title="Cucumbers" img="cucumbers.jpg" description="With it's gentle Taste is our Cucumber a culinary Delight for all Mankind!" />
                    <Product onAdd={() => this.addItem(1, 'pears', 4.99)} title="Pears" img="pears.jpg" description="Pears! Is there anything to say? Probably only, that it tastes absolutely like Heaen. Buy and Try!" />
                </div>
                <ShoppingCart items={this.state.items}/>
            </div>
            <div className="product-container">
                    <Product onAdd={() => this.addItem(1, 'appless', 2.99)} title="Apples" img="apples.jpg" description="One Apple a day keeps the doctor away. And bad Weather!" />
                    <Product onAdd={() => this.addItem(1, 'plums', 1.99)} title="Plums" img="plums.jpg" description="Small, but with a huge Tasting! You'll never forget our Peachs and their Taste" />
                    <Product onAdd={() => this.addItem(1, 'bananas', 3.99)} title="Bananas" img="bananas.jpg" description="Imported directly from the Amazonas to Germany! You can taste the oriental note." />
                    <Product onAdd={() => this.addItem(1, 'peach', 4.99)} title="Peachs" img="peach.jpg" description="Peach! Absolutely Ass, but" />
                </div>
                <Footer />
        </React.Fragment>
    }
}

export default App;