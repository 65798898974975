import React, { Component } from 'react';

class Navbar extends Component {
    state = {  } 
    render() { 
        return <nav className="navbar bg-light">
            <div className="container-fluid">
                <a className="navbar-brand navbar-text" href="#">Navbar</a>
                <a className="navbar-brand" href="#">Konto</a>
            </div>
        </nav>;
    }
}
 
export default Navbar;