import React, { Component } from 'react';

class ShoopingCart extends Component {
    state = {} 
    render() { 
        return <div className="shopping-cart">
            <h1 className="badge bg-primary">Shopping-Cart</h1>
            <ul className="list-group">
            { this.props.items.map(item => <li className="list-group-item" key={item.name}>{item.amount} x {item.name}: {item.price} €</li>)}
            </ul>
        </div>;
    }
}
 
export default ShoopingCart;